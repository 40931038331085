export default {
  "section-container": "_section-container_tk0tng",
  "header-container": "_header-container_tk0tng",
  "full-border": "_full-border_tk0tng",
  "component-details": "_component-details_tk0tng",
  "multiple-parents-container": "_multiple-parents-container_tk0tng",
  "multiple-parents-header": "_multiple-parents-header_tk0tng",
  "multiple-parents-link": "_multiple-parents-link_tk0tng",
  "loading-container": "_loading-container_tk0tng"
};
